@import url('https://fonts.googleapis.com/css?family=Quicksand');



/* body {
  width: 100%;
  height: 100%;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/518746/art-artistic-black-background-752484-min.jpg");
    background-size: cover;
} */

/* body:before {
  width: 100%;
  height: 100%;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(
    to bottom right,
    rgba(29, 38, 113, 0.75),
    rgba(195, 65, 150, 0.75)
  );
  background-size: auto;
} */

.loader {
  animation: spinningColor 1.5s ease-in-out infinite;
  margin: 50px auto;
  border: 5px double #f0eff5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spinningColor {
  0% {
    transform: rotate(360deg);
    border-top:5px dashed #efede9;
    border-bottom:5px dashed #666769;
  }
  25% {
    border-top:5px dashed #ffc200;
    border-bottom:5px dashed #2e9ceb;
  }
  50% {
    border-top:5px dashed #2e9ceb;
    border-bottom:5px dashed #2e9ceb;
  }
  75% {
    border-top:5px dashed #2e9ceb;
    border-bottom:5px dashed #ffc200;
  }
  100% {
    border-top:5px dashed #666769;
    border-bottom:5px dashed #efede9;
  }
}
