.detailer{
    position: fixed; 
    right: 0;
    top: 150px; 
    max-width: 400px;
}
 @media screen and (max-width: 768px) {
    .detailer{
        position: fixed; 
        top: 60%; 
        bottom: 0%;
        max-width: 100%;
        width: 100%;
    }
    .hide{
        display: none;
    }
    .space{
        height: 300px;
    }
    
 }