.detailer{
    position: fixed; 
    right: 0;
    top: 160px; 
    max-width: 350px;
}
 @media screen and (max-width: 768px) {
    .detailer{
        position: static; 
        bottom: 0%;
        max-width: 100%;
        width: 100%;
    }
    .hide{
        display: none;
    }
    .space{
        height: 10px;
    }
    
 }
.dimensions{
    position:relative;
    min-width: 350px;
}